import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import swap_icon from '../images/swapicon.png';
import star_grey from '../images/Bullet Point Icon.png';
import star_gold from '../images/Bullet Point Icon Gold.png';
import credit_card from '../images/Credit Card Icon.png';
import {List, AutoSizer} from "react-virtualized";


import {toast, Bounce } from 'react-toastify';
import axios from 'axios';


const ExchangeBox = (props) => {

  //Navigate Hook
  const navigate = useNavigate();

    //States
    const [senddropvisibility, setSendDropVisibility] = useState("none");
    const [getdropvisibility, setGetDropVisibility] = useState("none");
    const [isLoading, setIsLoading] = useState(true);
    const [cr, setCr] = useState();
    const [sendcryptodrop, setSendCryptoDrop] = useState("none");
    const [getcryptodrop, setGetCryptoDrop] = useState("none");
    const [sendcryptoinput, setSendCryptoInput] = useState("flex");
    const [getcryptoinput, setGetCryptoInput] = useState("flex");
    const [sendindex, setSendIndex]=useState(localStorage.getItem("local_send_index"));
    const [getindex, setGetIndex]=useState(localStorage.getItem("local_get_index"));
    const [sendamount, setSendAmount]=useState(localStorage.getItem("local_send_amount"));
    const [getamount, setGetAmount]=useState("");
    const [loadingdots, setLoadingDots]=useState(false);
    const [exchangetab, setExchangeTab]=useState(true);
    const [popularcurrenciestab, setPopularCurrenciesTab]=useState(true);
    const [stablecurrenciestab, setStableCurrenciesTab]=useState(false);
    const [othercurrenciestab, setOtherCurrenciesTab]=useState(false);
    const [buytab, setBuyTab]=useState(false);
    const [sendsearch, setSendSearch]=useState("");
    const [getsearch, setGetSearch]=useState("");
    const [sendcryptoarray, setSendCryptoArray]=useState([]);
    const [getcryptoarray, setGetCryptoArray]=useState([]);
    const [minamount, setMinAmount]=useState(0);
    const [estimatedAmount, setestimatedAmount]=useState("0.0");
    const [popularcoinsvisibility ,setPopularCoinsVisibility]=useState("block");
    const [stablecoinsvisibility ,setStableCoinsVisibility]=useState("block");
    const [othercoinsvisibility ,setOtherCoinsVisibility]=useState("block");
    let priceInterval;

    //Move to offers page
    const goToNavigatePage = () => {
      navigate('/best_crypto_to_crypto_exchange');
    };

    const handleSendSearchChange = (e) => {

      setSendSearch(e.target.value);
      const searchTerm = e.target.value.toLowerCase();
  
      const filteredCryptos = cr.filter((crypto) => {
        const { symbol2, symbol } = crypto;
        return (
          symbol2.toLowerCase().includes(searchTerm) ||
          symbol.toLowerCase().includes(searchTerm)
        );
      });
  
      const exactMatch = [];
      const partialMatch = [];
  
      filteredCryptos.forEach((crypto) => {
        const { symbol2, symbol } = crypto;
        if (
          symbol2.toLowerCase() === searchTerm ||
          symbol.toLowerCase() === searchTerm
        ) {
          exactMatch.push(crypto);
        } else {
          partialMatch.push(crypto);
        }
      });
  
      const rearrangedCryptos = exactMatch.concat(partialMatch);

      const countMatchingProperties=(array, property, valueToMatch)=>{
        return array.filter(item => item[property] === valueToMatch).length;
    }

    const popular = countMatchingProperties(rearrangedCryptos, "popular", true);
    const stable = countMatchingProperties(rearrangedCryptos, "isstable", true);
    const other = countMatchingProperties(rearrangedCryptos, "othercoin", true);

    if(popular==0){
      setPopularCoinsVisibility("none");
    }else{
      setPopularCoinsVisibility("block");
    }
    if(stable==0){
      setStableCoinsVisibility("none");
    }else{
      setStableCoinsVisibility("block");
    }
    if(other==0){
      setOtherCoinsVisibility("none");
    }else{
      setOtherCoinsVisibility("block");
    }

      setSendCryptoArray(rearrangedCryptos);
    };

    const handleError=(toastErrorTime, toastErrorMessage)=>{
      toast.dismiss();
      toast.error(
       <div>
           <strong>Something went wrong</strong>
           <p style={{fontSize:"13px", color:"#888787"}}>{toastErrorMessage}</p>
       </div>
       ,
       {
           position: "top-right",
           autoClose: toastErrorTime,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "dark",
           transition: Bounce,
       });
   }

   let handeonetimeerrorstate;
   const handleOneTimeErrorState=(state)=>{
    handeonetimeerrorstate=state;
}

    const handleGetSearchChange = (e) => {
      setGetSearch(e.target.value);
      const searchTerm = e.target.value.toLowerCase();
  
      const filteredCryptos = cr.filter((crypto) => {
        const { symbol2, symbol } = crypto;
        return (
          symbol2.toLowerCase().includes(searchTerm) ||
          symbol.toLowerCase().includes(searchTerm)
        );
      });
  
      const exactMatch = [];
      const partialMatch = [];
  
      filteredCryptos.forEach((crypto) => {
        const { symbol2, symbol } = crypto;
        if (
          symbol2.toLowerCase() === searchTerm ||
          symbol.toLowerCase() === searchTerm
        ) {
          exactMatch.push(crypto);
        } else {
          partialMatch.push(crypto);
        }
      });
  
      const rearrangedCryptos = exactMatch.concat(partialMatch);
      const countMatchingProperties=(array, property, valueToMatch)=> {
        return array.filter(item => item[property] === valueToMatch).length;
    }

    const popular = countMatchingProperties(rearrangedCryptos, "popular", true);
    const stable = countMatchingProperties(rearrangedCryptos, "isstable", true);
    const other = countMatchingProperties(rearrangedCryptos, "othercoin", true);

    if(popular==0){
      setPopularCoinsVisibility("none");
    }else{
      setPopularCoinsVisibility("block");
    }
    if(stable==0){
      setStableCoinsVisibility("none");
    }else{
      setStableCoinsVisibility("block");
    }
    if(other==0){
      setOtherCoinsVisibility("none");
    }else{
      setOtherCoinsVisibility("block");
    }
      setGetCryptoArray(rearrangedCryptos);
    };

    function formatTo15Chars(input, length) {
      // Step 1: Convert to string if it's a number
      let value = typeof input === 'number' ? input.toString() : input;
    
      // Step 2: Trim the string to a maximum of 15 characters (including decimal point)
      value = value.slice(0, length);
    
      return value;
    }

  var getprice=async()=>{
    setLoadingDots(true);
  try {
    if(!isLoading){
      const url=process.env.REACT_APP_URL+"/pricecheck";
      const options={
        sel: cr[sendindex]?.symbol,
        get: cr[getindex]?.symbol,
        amount: sendamount
      };
      
      const headers= {
        headers: {
          "Content-Type": "application/json"
        }
      };
  
      const response = await axios.post(url, options, headers);
    
      const data = response.data; 
      setLoadingDots(false);
      setGetAmount(formatTo15Chars(data.to.amount, 15)); 
      setMinAmount(parseFloat(data.to.from.min?data.to.from.min:0));
      sessionStorage.setItem('minAmount', parseFloat(data.to.from.min?data.to.from.min:0))
      const estimate=parseFloat(data.to.onesel).toPrecision(15);
      setestimatedAmount(estimate?estimate:0.0);
    }
    handleOneTimeErrorState(false);
    toast.dismiss();
  } catch (error) {
                if(!handeonetimeerrorstate){
                    handleError(false, "Please check your connection and try reloading the page.")
                    handleOneTimeErrorState(true);
                }
  }

}

    useEffect(()=>{
      localStorage.setItem("local_send_amount", sendamount);
      
      // Function to restart the timer
     function restartInterval() {
        clearInterval(priceInterval); // Clear the existing interval
        priceInterval = setInterval(getprice, 2*60000); // Set a new interval
      }
    
      // Initial call to fetch data and start the timer
      getprice();
      restartInterval();
    
      // Cleanup function to clear the interval
      return () => clearInterval(priceInterval);
    },[cr,sendamount,sendindex,getindex])

          //Get crypto function
          const getcrypto=async()=>{
            try {
              const default_array=JSON.parse(localStorage.getItem("coin_default_array"));
              setCr(default_array);
              setIsLoading(false);
              if(props.cryptoData){
                setCr(props.cryptoData);
                localStorage.setItem("coin_default_array", JSON.stringify(props.cryptoData));
              }
            } catch (error) {
              handleError(false, "Please check your connection and try reloading the page.")
            }
          }
    
        useEffect(()=>{
        getcrypto();
      },[props.cryptoData]);

    useEffect(()=>{
      document.addEventListener("click", handleClickOutside, true);
    },[])

    // Send search visibility
   const toggleSendVisibility = () => {
    setSendCryptoDrop("flex");
    setSendCryptoInput("none");
    setSendDropVisibility("block");

    setGetCryptoDrop("none");
    setGetCryptoInput("flex");
    setGetDropVisibility("none");
  };

    // Get search visibility
   const toggleGetVisibility = () => {
    setGetCryptoDrop("flex");
    setGetCryptoInput("none");
    setGetDropVisibility("block");

    setSendCryptoDrop("none");
    setSendCryptoInput("flex");
    setSendDropVisibility("none");
    };

  const swapcryptoindex=()=>{
    setestimatedAmount("0.0");
    const variable=sendindex;
    setSendIndex(getindex);
    setGetIndex(variable); 
    localStorage.setItem("local_send_index", getindex);
    localStorage.setItem("local_get_index", variable);
  }

    //Handle outside clicks
    const handleClickOutside = (e) => {
        const cn = e.target.className;
    
        if (
          cn === "input-box" ||
          cn === "dropdown-content-send" ||
          cn === "dropdown-content-get" ||
          cn === "coin-drop-div" ||
          cn === "search-icon"||
          cn ==="exchange-send-div-input-drop-box"||
          cn === "form-control"||
          cn === "exchange-send-div-input"||
          cn === "exchange-send-label"||
          cn === "dont-close-send"||
          cn === "search-box" ||
          cn === "fa-solid fa-magnifying-glass search-icon"||
          cn === "form-control input-box"||
          cn === "btn  popular-currencies-btn"||
          cn === "btn  stable-currencies-btn"||
          cn === "btn  other-currencies-btn"

        ) {
          //Do nothing
        } 
        
        else {
            setSendCryptoDrop("none");
            setSendCryptoInput("flex");
            setSendDropVisibility("none");

            setGetCryptoDrop("none");
            setGetCryptoInput("flex");
            setGetDropVisibility("none");

            setSendCryptoArray([]);
            setGetCryptoArray([]);

            setSendSearch("");
            setGetSearch("");

            setPopularCurrenciesTab(true);
            setStableCurrenciesTab(false);
            setOtherCurrenciesTab(false);
        }
      };


      const handleScrollToElementSend = (elementId) => {
        // Prevent the default anchor behavior
        const dropdownContent = document.querySelector('.scrollable-dropdown-send');
        const targetElement = document.getElementById(elementId);

        if (dropdownContent && targetElement) {
            const targetTop = targetElement.offsetTop;
            const dropdownScrollTop = dropdownContent.scrollTop;
            const dropdownHeight = dropdownContent.offsetHeight;
            console.log(dropdownHeight)

            // Calculate if the target element is out of view within the dropdown
            if (targetTop < dropdownScrollTop || (targetTop > dropdownScrollTop + dropdownHeight)) {
                // Align the target element at the top of the dropdown
                dropdownContent.scrollTop = targetTop-70;
            }
        }
    };

    const handleScrollToElementGet = (elementId) => {
      // Prevent the default anchor behavior
      const dropdownContent = document.querySelector('.scrollable-dropdown-get');
      const targetElement = document.getElementById(elementId);

      if (dropdownContent && targetElement) {
          const targetTop = targetElement.offsetTop;
          const dropdownScrollTop = dropdownContent.scrollTop;
          const dropdownHeight = dropdownContent.offsetHeight;
          console.log(dropdownHeight)

          // Calculate if the target element is out of view within the dropdown
          if (targetTop < dropdownScrollTop || (targetTop > dropdownScrollTop + dropdownHeight)) {
              // Align the target element at the top of the dropdown
              dropdownContent.scrollTop = targetTop-70;
          }
      }
  };

  return (
    <>
    <div className='exchangebox-size-adjust'>
    <div className='exchange-box-div'>
    <div><div>Available Soon</div></div>
        <div className='exchange-box-tab'>
        <button type="button" className="btn  exchange-box-tab-exchange" style={{backgroundColor:exchangetab?"black":"#E3E1DE", color:exchangetab?"#E3E1DE":"black"}} onClick={()=>{setExchangeTab(true); setBuyTab(false);}}>Exchange Crypto</button>
        <button type="button" className="btn  exchange-box-tab-buy" style={{backgroundColor:buytab?"black":"#E3E1DE", color:buytab?"#E3E1DE":"black"}}><div>Buy/Sell Crypto<img src={credit_card} className='card_icon'></img></div></button> 
        </div>
        <div className='exchange-inputs-div'>
            <div className='exchange-send-div' style={{border:sendamount<minamount?"1px solid red":"1px solid #2A2927"}}>
            {/* border:sendamount<minamount?"1px solid red":"1px solid red" */}
            {/* #2A2927 */}
                <div  style={{display:sendcryptoinput, width:"100%"}}>
                <div className='exchange-send-div-input'>
                  <div>
                    <label className='exchange-send-label'>You Send:</label>
                    <input type="txt" className="form-control" id="exchange-box-send-input" aria-describedby="emailHelp"  value={sendamount} onChange={(e)=>{
                      // Replace commas with periods in the input value
                      const modifiedValue = e.target.value.replace(/,/g, '.');

                      // Regular expression to allow only numbers and a single decimal point
                      if (/^\d*\.?\d*$/.test(modifiedValue)) {
                          setSendAmount(modifiedValue); // Update state only if input is valid (numbers and one decimal point)
                      }
                      }}/>
                  </div>
                </div>
                <div className='exchange-send-div-input-drop'>
                    <div className='exchange-send-div-input-drop-box'  id="thumb-cursor" onClick={(e)=>{toggleSendVisibility(e)}}>
                        <img src={isLoading?"":cr[sendindex].image} id="thumb-cursor" className='send-crypto-img dont-close-send' loading='lazy'></img>
                        <div id="thumb-cursor" className='dont-close-send currency-send-label-div'>
                            <label id="thumb-cursor" className='dont-close-send btn-send-label'>{isLoading?"":cr[sendindex].shortname.toUpperCase()}</label>
                            { 
                               isLoading?"":<div className='network-div' style={{border:`1px solid ${cr[sendindex].networkcolor}`, display:cr[sendindex].networkcolor!==""?"block":"none"}}><span style={{color:cr[sendindex].networkcolor}}>{cr[sendindex].network.toUpperCase()}</span></div>                             
                            }
                            <i id="thumb-cursor" className='fa-solid fa-chevron-down dont-close-send fa-send'></i> 
                        </div>
                    </div>
                </div>
                </div>
                <div className='search-box' style={{display:sendcryptodrop}}>
                    <i className="fa-solid fa-magnifying-glass search-icon" ></i>
                    <input type="txt" className="form-control input-box" value={sendsearch} autofocus placeholder="Type currency here" onChange={(e)=>{handleSendSearchChange(e)}}/>
                </div>
            </div>
            <div style={{position:"relative", width:"100%"}}>
                <div className="dropdown-content-send " style={{display:senddropvisibility}}>
         <div className='type-of-coins-tab'>
        <button type='button' className="btn  popular-currencies-btn" style={{backgroundColor:popularcurrenciestab?"black":"#E3E1DE", color:popularcurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementSend('popular-send'); setPopularCurrenciesTab(true); setStableCurrenciesTab(false); setOtherCurrenciesTab(false);}}>Popular Currencies</button>
        <button type='button'  className="btn  stable-currencies-btn" style={{backgroundColor:stablecurrenciestab?"black":"#E3E1DE", color:stablecurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementSend('stable-send'); setPopularCurrenciesTab(false); setStableCurrenciesTab(true); setOtherCurrenciesTab(false);}}>Stable Currencies</button>
        <button type='button' className="btn  other-currencies-btn" style={{backgroundColor:othercurrenciestab?"black":"#E3E1DE", color:othercurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementSend('other-send'); setStableCurrenciesTab(false); setPopularCurrenciesTab(false); setOtherCurrenciesTab(true);}}>Other Currencies</button>
        </div>

{isLoading?"":<div className='scrollbox scrollable-dropdown-send'>
      
      {/* Popular Coins Section */}
      <span id='popular-send' style={{ display: popularcoinsvisibility, fontFamily: "basementgrotesque", textAlign: "center" }}>Popular Currencies</span>
      
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            style={{display:popularcoinsvisibility}}
            height={65*(sendcryptoarray.filter(coin => coin.popular).length>0?sendcryptoarray.filter(coin => coin.popular).length:cr.filter(coin => coin.popular).length)} // Adjust height as needed
            rowCount={sendcryptoarray.filter(coin => coin.popular).length>0?sendcryptoarray.filter(coin => coin.popular).length:cr.filter(coin => coin.popular).length}
            rowHeight={65} // Adjust row height as needed
            rowRenderer={({ index, key, style }) => {
              const coin = sendcryptoarray.filter(coin => coin.popular).length>0?sendcryptoarray.filter(coin => coin.popular)[index]:cr.filter(coin => coin.popular)[index]
              return (
                <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => {setSendIndex(coin.coinindex); localStorage.setItem("local_send_index",coin.coinindex); setestimatedAmount("0.0");}}>
                  <div className='coin-drop-div'>
                    <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                      <img src={coin.image} id="thumb-cursor" style={{ height: "35px", width: "35px" }}/>
                      <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                        <b id="thumb-cursor"><span id="thumb-cursor" style={{ display: "block" }}>{coin.shortname.toUpperCase()}</span></b>
                      </div>
                    </div>
                    <div className='dropdown-network-name' style={{ background: coin.networkcolor }}>{coin.network.toUpperCase()}</div>
                    <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                  </div>
                  <div className='coins-drop-underline' style={{ width: "100%", height: "1px" }}></div>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
      
      {/* Stable Coins Section */}
      <span id='stable-send' style={{ display: stablecoinsvisibility, fontFamily: "basementgrotesque", marginTop: "30px", textAlign: "center" }}>Stable Currencies</span>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            style={{display:stablecoinsvisibility}}
            height={65*(sendcryptoarray.filter(coin => coin.isstable).length>0?sendcryptoarray.filter(coin => coin.isstable).length:cr.filter(coin => coin.isstable).length)}
            // rowCount={cr.filter(coin => coin.isstable).length}
            rowCount={sendcryptoarray.filter(coin => coin.isstable).length>0?sendcryptoarray.filter(coin => coin.isstable).length:cr.filter(coin => coin.isstable).length}
            rowHeight={65}
            rowRenderer={({ index, key, style }) => {
              // const coin = cr.filter(coin => coin.isstable)[index];
              const coin = sendcryptoarray.filter(coin => coin.isstable).length>0?sendcryptoarray.filter(coin => coin.isstable)[index]:cr.filter(coin => coin.isstable)[index]
              return (
                <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => {setSendIndex(coin.coinindex); localStorage.setItem("local_send_index",coin.coinindex); setestimatedAmount("0.0");}}>
                  <div className='coin-drop-div'>
                    <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                      <img src={coin.image} id="thumb-cursor" style={{ height: "35px", width: "35px" }} />
                      <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                        <b id="thumb-cursor"><span id="thumb-cursor" style={{ display: "block" }}>{coin.shortname.toUpperCase()}</span></b>
                      </div>
                    </div>
                    <div className='dropdown-network-name' style={{ background: coin.networkcolor }}>{coin.network.toUpperCase()}</div>
                    <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                  </div>
                  <div className='coins-drop-underline' style={{ width: "100%", height: "1px" }}></div>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
      
      {/* Other Coins Section */}
      <span id='other-send' style={{ display: othercoinsvisibility, fontFamily: "basementgrotesque", marginTop: "30px", textAlign: "center" }}>Other Currencies</span>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            style={{display:othercoinsvisibility}}
            height={65*(sendcryptoarray.filter(coin => coin.othercoin).length>0?sendcryptoarray.filter(coin => coin.othercoin).length:cr.filter(coin => coin.othercoin).length)}
            // rowCount={cr.filter(coin => coin.othercoin).length}
            rowCount={sendcryptoarray.filter(coin => coin.othercoin).length>0?sendcryptoarray.filter(coin => coin.othercoin).length:cr.filter(coin => coin.othercoin).length}
            rowHeight={65}
            rowRenderer={({ index, key, style }) => {
              // const coin = cr.filter(coin => coin.othercoin)[index];
              const coin = sendcryptoarray.filter(coin => coin.othercoin).length>0?sendcryptoarray.filter(coin => coin.othercoin)[index]:cr.filter(coin => coin.othercoin)[index]
              return (
                <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => {setSendIndex(coin.coinindex); localStorage.setItem("local_send_index",coin.coinindex); setestimatedAmount("0.0");}}>
                  <div className='coin-drop-div'>
                    <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                      <img src={coin.image} id="thumb-cursor" style={{ height: "35px", width: "35px" }} />
                      <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                        <b id="thumb-cursor"><span id="thumb-cursor" style={{ display: "block" }}>{coin.shortname.toUpperCase()}</span></b>
                      </div>
                    </div>
                    <div className='dropdown-network-name' style={{ background: coin.networkcolor }}>{coin.network.toUpperCase()}</div>
                    <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                  </div>
                  <div className='coins-drop-underline' style={{ width: "100%", height: "1px" }}></div>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
      </div>}
        </div>
                </div>
            <div className='swap-btn-div'>
                <div className='swap-btn' id='thumb-cursor' onClick={swapcryptoindex}>
                    <img src={swap_icon} style={{height:"24px"}} loading='lazy'></img>
                </div>
            </div>
            <div className='exchange-get-div'>
                <div  style={{display:getcryptoinput, width:"100%"}}>
                <div className='exchange-get-div-input'>
                  <div>
                    <label className='exchange-get-label'>You Recieve:</label>
                    <input type="txt" className="form-control" style={{display:loadingdots?"none":"block"}}  id="exchange-box-get-input" aria-describedby="emailHelp" value={getamount} disable/>
                    <div className="dot-pulse" style={{display:loadingdots?"block":"none"}}></div>
                  </div>
                </div>
                <div className='exchange-get-div-input-drop'>
                    <div className='exchange-get-div-input-drop-box' id="thumb-cursor" onClick={toggleGetVisibility}>
                    <img src={isLoading?"":cr[getindex].image} id="thumb-cursor" className="get-crypto-img dont-close-send" loading='lazy'></img>
                    <div id="thumb-cursor" className='currency-get-label-div'>
                        <label id="thumb-cursor" className='btn-get-label'>{isLoading?"":cr[getindex].shortname.toUpperCase()}</label>
                        { 
                               isLoading?"":<div className='network-div' style={{border:`1px solid ${cr[getindex].networkcolor}`, display:cr[getindex].networkcolor!==""?"block":"none"}}><span style={{color:cr[getindex].networkcolor}}>{cr[getindex].network.toUpperCase()}</span></div>                             
                        }
                        <i id="thumb-cursor" className='fa-solid fa-chevron-down fa-get'></i>
                    </div>
                    </div>
                </div>
                </div>
                <div className='search-box' style={{display:getcryptodrop}}>
                    <i className="fa-solid fa-magnifying-glass search-icon" ></i>
                    <input type="txt" className="form-control input-box" value={getsearch} placeholder="Type currency here" onChange={(e)=>{handleGetSearchChange(e)}}/>
                </div>
            </div>
            <div style={{position:'relative', width:"100%"}}>
            <div className="dropdown-content-get" style={{display:getdropvisibility}}>
                <div className='type-of-coins-tab'>
                    <button type="button" className="btn  popular-currencies-btn" style={{backgroundColor:popularcurrenciestab?"black":"#E3E1DE", color:popularcurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementGet('popular-get'); setPopularCurrenciesTab(true); setStableCurrenciesTab(false); setOtherCurrenciesTab(false);}}>Popular Currencies</button>
                    <button type="button" className="btn  stable-currencies-btn" style={{backgroundColor:stablecurrenciestab?"black":"#E3E1DE", color:stablecurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementGet('stable-get'); setPopularCurrenciesTab(false); setStableCurrenciesTab(true); setOtherCurrenciesTab(false);}}>Stable Currencies</button>
                    <button type="button" className="btn  other-currencies-btn" style={{backgroundColor:othercurrenciestab?"black":"#E3E1DE", color:othercurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementGet('other-get'); setPopularCurrenciesTab(false); setStableCurrenciesTab(false); setOtherCurrenciesTab(true);}}>Other Currencies</button>
                </div>

{!isLoading && (
  <div className="scrollbox scrollable-dropdown-get">

    {/* Popular Coins Section */}
    <span
      id="popular-get"
      style={{
        display: popularcoinsvisibility,
        fontFamily: "basementgrotesque",
        textAlign: "center",
      }}
    >
      Popular Currencies
    </span>

    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          style={{ display: popularcoinsvisibility }}
          height={65*(getcryptoarray.filter(coin => coin.popular).length > 0 ? getcryptoarray.filter(coin => coin.popular).length : cr.filter(coin => coin.popular).length)} // Adjust height as needed
          rowCount={
            getcryptoarray.filter(coin => coin.popular).length > 0 ? getcryptoarray.filter(coin => coin.popular).length : cr.filter(coin => coin.popular).length
          }
          rowHeight={65} // Adjust row height as needed
          rowRenderer={({ index, key, style }) => {
            const coin =
              getcryptoarray.filter(coin => coin.popular).length > 0
                ? getcryptoarray.filter(coin => coin.popular)[index]
                : cr.filter(coin => coin.popular)[index];
            return (
              <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => {setGetIndex(coin.coinindex);  localStorage.setItem("local_get_index",coin.coinindex); setestimatedAmount("0.0");}}>
                <div className="coin-drop-div">
                  <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                    <img
                      src={coin.image}
                      id="thumb-cursor"
                      style={{ height: "35px", width: "35px" }}
                      alt={coin.shortname}
                    />
                    <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                      <b id="thumb-cursor">
                        <span id="thumb-cursor" style={{ display: "block" }}>
                          {coin.shortname.toUpperCase()}
                        </span>
                      </b>
                    </div>
                  </div>
                  <div className="dropdown-network-name" style={{ background: coin.networkcolor }}>
                    {coin.network.toUpperCase()}
                  </div>
                  <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                </div>
                <div className="coins-drop-underline" style={{ width: "100%", height: "1px" }}></div>
              </div>
            );
          }}
        />
      )}
    </AutoSizer>

    {/* Stable Coins Section */}
    <span
      id="stable-get"
      style={{
        display: stablecoinsvisibility,
        fontFamily: "basementgrotesque",
        marginTop: "30px",
        textAlign: "center",
      }}
    >
      Stable Currencies
    </span>

    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          style={{ display: stablecoinsvisibility }}
          height={65*(getcryptoarray.filter(coin => coin.isstable).length > 0 ? getcryptoarray.filter(coin => coin.isstable).length : cr.filter(coin => coin.isstable).length)}
          rowCount={
            getcryptoarray.filter(coin => coin.isstable).length > 0 ? getcryptoarray.filter(coin => coin.isstable).length : cr.filter(coin => coin.isstable).length
          }
          rowHeight={65}
          rowRenderer={({ index, key, style }) => {
            const coin =
              getcryptoarray.filter(coin => coin.isstable).length > 0
                ? getcryptoarray.filter(coin => coin.isstable)[index]
                : cr.filter(coin => coin.isstable)[index];
            return (
              <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => {setGetIndex(coin.coinindex);  localStorage.setItem("local_get_index",coin.coinindex); setestimatedAmount("0.0");}}>
                <div className="coin-drop-div">
                  <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                    <img
                      src={coin.image}
                      id="thumb-cursor"
                      style={{ height: "35px", width: "35px" }}
                      alt={coin.shortname}
                    />
                    <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                      <b id="thumb-cursor">
                        <span id="thumb-cursor" style={{ display: "block" }}>
                          {coin.shortname.toUpperCase()}
                        </span>
                      </b>
                    </div>
                  </div>
                  <div className="dropdown-network-name" style={{ background: coin.networkcolor }}>
                    {coin.network.toUpperCase()}
                  </div>
                  <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                </div>
                <div className="coins-drop-underline" style={{ width: "100%", height: "1px" }}></div>
              </div>
            );
          }}
        />
      )}
    </AutoSizer>

    {/* Other Coins Section */}
    <span
      id="other-get"
      style={{
        display: othercoinsvisibility,
        fontFamily: "basementgrotesque",
        marginTop: "30px",
        textAlign: "center",
      }}
    >
      Other Currencies
    </span>

    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          style={{ display: othercoinsvisibility }}
          height={65*(getcryptoarray.filter(coin => coin.othercoin).length > 0 ? getcryptoarray.filter(coin => coin.othercoin).length  : cr.filter(coin => coin.othercoin).length)}
          rowCount={
            getcryptoarray.filter(coin => coin.othercoin).length > 0 ? getcryptoarray.filter(coin => coin.othercoin).length  : cr.filter(coin => coin.othercoin).length
          }
          rowHeight={65}
          rowRenderer={({ index, key, style }) => {
            const coin =
              getcryptoarray.filter(coin => coin.othercoin).length > 0
                ? getcryptoarray.filter(coin => coin.othercoin)[index]
                : cr.filter(coin => coin.othercoin)[index];
            return (
              <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => {setGetIndex(coin.coinindex);  localStorage.setItem("local_get_index",coin.coinindex); setestimatedAmount("0.0");}}>
                <div className="coin-drop-div">
                  <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                    <img
                      src={coin.image}
                      id="thumb-cursor"
                      style={{ height: "35px", width: "35px" }}
                      alt={coin.shortname}
                    />
                    <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                      <b id="thumb-cursor">
                        <span id="thumb-cursor" style={{ display: "block" }}>
                          {coin.shortname.toUpperCase()}
                        </span>
                      </b>
                    </div>
                  </div>
                  <div className="dropdown-network-name" style={{ background: coin.networkcolor }}>
                    {coin.network.toUpperCase()}
                  </div>
                  <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                </div>
                <div className="coins-drop-underline" style={{ width: "100%", height: "1px" }}></div>
              </div>
            );
          }}
        />
      )}
    </AutoSizer>
  </div>
)}
        </div>
            </div>
            <div>
            <div className='min-estimate-div' style={{marginLeft:"6px"}}>
                <span className='min-amount' id="thumb-cursor" onClick={()=>{setSendAmount(minamount)}}><img src={star_grey} style={{height:"24px"}} loading='lazy'></img><label id="thumb-cursor" className='min-amount'>Min. Amount: {minamount}</label></span>
                <span className='estimated-amount'><img src={star_gold} style={{height:"24px"}} loading='lazy'></img><label className='est-amount'>Estimated: 1 {isLoading?"":cr[sendindex].shortname.toUpperCase()} ~ {formatTo15Chars(estimatedAmount, 15)} {isLoading?"":cr[getindex].shortname.toUpperCase()}</label></span>
            </div>
            <button type="button" onClick={goToNavigatePage} className="btn offer-btn">View Offers</button>
        </div>
        </div>

    </div>
    </div>
    </>
  );
};

export default ExchangeBox;
