import React, {useState, useEffect, useRef} from 'react';
import Offer from '../Components/offer';
import {List, AutoSizer, CellMeasurer, CellMeasurerCache} from "react-virtualized";
import fixedicon from '../images/Fixed Icon.png';
import floatingicon from '../images/Floating Icon.png';
import step2 from '../images/step2.png';
import step2mobileui from '../images/How To Swap Crypto.png';
import swap_icon from '../images/swapicon.png';
import credit_card from '../images/Credit Card Icon.png';
import refresh_circle from '../images/Refresh Circle.png';
import StaticBestRatingOffers from '../Components/staticBestRatingOffers.js';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import {toast, Bounce } from 'react-toastify';
import axios from 'axios';
import { Helmet } from 'react-helmet';


const Offers=(props)=>{

    const [bestoffertabcolor, setBestOfferTabColor]=useState("white");
    const [bestoffertabtextcolor, setBestOfferTabTextColor]=useState("black");
    const [fastestoffertabcolor, setFastestOfferTabColor]=useState("transparent");
    const [fastestoffertabtextcolor, setFastestOfferTabTextColor]=useState("white");
    const [fastestratedoffertabcolor, setFastestRatedOfferTabColor]=useState("transparent");
    const [fastestratedoffertabtextcolor, setFastestRatedOfferTabTextColor]=useState("white");
    const [popularcurrenciestab, setPopularCurrenciesTab]=useState(true);
    const [stablecurrenciestab, setStableCurrenciesTab]=useState(false);
    const [othercurrenciestab, setOtherCurrenciesTab]=useState(false);
    const [popularcoinsvisibility ,setPopularCoinsVisibility]=useState("block");
    const [stablecoinsvisibility ,setStableCoinsVisibility]=useState("block");
    const [othercoinsvisibility ,setOtherCoinsVisibility]=useState("block");
    const [isActive, setIsActive] = useState(false);    
    const [timer, setTimer] = useState(false);
    const [fixedtabcolor, setFixedTabColor]=useState("transparent");
    const [fixedtabtextcolor, setFixedTabTextColor]=useState("white");
    const [floatingtabcolor, setFloatingTabColor]=useState("white");
    const [floatingtabtextcolor, setFloatingTabTextColor]=useState("black");
    const [fixedfilter, setFixedFilter]=useState("brightness(2000%)");
    const [floatingfilter, setFloatingFilter]=useState("brightness(1%)");
    const [offertype, setOfferType]=useState("bestprices");
    const [offerarray, setOfferArray]=useState([]);
    const [transactiontype, setTransactionType]=useState("Floating");
    const [timerTrigger, setTimerTrigger]=useState(false);
    const [onloaddummyoffers, setOnloadDummyOffers]=useState([
      {
          name: "stealthex",
          transaction_type: "Floating",
          eta: "7-38 Min",
          kyc: "On Occasion",
          rating: "4.7/5",
      },
      {
          name: "stealthex",
          transaction_type: "Fixed",
          eta: "7-38 Min",
          kyc: "On Occasion",
          rating: "4.7/5",
      },
      {
          name: "letsexchange",
          transaction_type: "Floating",
          eta: "2-44 Min",
          kyc: "Not Required",
          rating: "4.6/5",
      },
      {
          name: "letsexchange",
          transaction_type: "Fixed",
          eta: "2-44 Min",
          kyc: "Not Required",
          rating: "4.6/5",
      },
      {
          name: "godex",
          transaction_type: "Floating",
          eta: "14-51 Min",
          kyc: "Rarely Required",
          rating: "4.6/5",
      },
      {
          name: "changenow",
          transaction_type: "Floating",
          eta: "10-60 Min",
          kyc: "On Occasion",
          rating: "4.5/5",
      },
      {
          name: "changenow",
          transaction_type: "Fixed",
          eta: "10-60 Min",
          kyc: "On Occasion",
          rating: "4.5/5",
      },
      {
          name: "simpleswap",
          transaction_type: "Floating",
          eta: "9-50 Min",
          kyc: "Rarely Required",
          rating: "4.4/5",
      },
      {
          name: "simpleswap",
          transaction_type: "Fixed",
          eta: "9-50 Min",
          kyc: "Rarely Required",
          rating: "4.4/5",
      },
      {
          name: "exolix",
          transaction_type: "Floating",
          eta: "4-20 Min",
          kyc: "Not Required",
          rating: "4.3/5",
      },
      {
          name: "exolix",
          transaction_type: "Fixed",
          eta: "4-20 Min",
          kyc: "Not Required",
          rating: "4.3/5",
      },
      {
          name: "changelly",
          transaction_type: "Floating",
          eta: "5-30 Min",
          kyc: "On Occasion",
          rating: "4.2/5",
      },
      {
          name: "changelly",
          transaction_type: "Fixed",
          eta: "5-30 Min",
          kyc: "On Occasion",
          rating: "4.2/5",
      },
      {
          name: "changehero",
          transaction_type: "Floating",
          eta: "12-26 Min",
          kyc: "On Occasion",
          rating: "3.7/5",
      },
      {
          name: "changehero",
          transaction_type: "Fixed",
          eta: "12-26 Min",
          kyc: "On Occasion",
          rating: "3.7/5",
      }
  ]);
    const [showonloaddummyoffers, setShowOnloadDummyOffers]=useState(true);

    function formatTo15Chars(input, length) {
      // Step 1: Convert to string if it's a number
      let value = typeof input === 'number' ? input.toString() : input;
    
      // Step 2: Trim the string to a maximum of 15 characters (including decimal point)
      value = value.slice(0, length);
    
      return value;
    }

    const handleToastError=(toastErrorTime, toastErrorMessage)=>{
      toast.dismiss();
      toast.error(
       <div>
           <strong>Something went wrong</strong>
           <p style={{fontSize:"13px", color:"#888787"}}>{toastErrorMessage}</p>
       </div>
       ,
       {
           position: "top-right",
           autoClose: toastErrorTime,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           theme: "dark",
           transition: Bounce,
       });
   }

   let handeonetimeerrorstate;
   const handleOneTimeErrorState=(state)=>{
    handeonetimeerrorstate=state;
}

    const bestratesfun=()=>{
        setBestOfferTabColor("white");
        setBestOfferTabTextColor("black");
        setFastestOfferTabColor("transparent");
        setFastestOfferTabTextColor("white");
        setFastestRatedOfferTabColor("transparent");
        setFastestRatedOfferTabTextColor("white");
        setOfferType("bestprices");
    }

    const fastestofferfun=()=>{
        setBestOfferTabColor("transparent");
        setBestOfferTabTextColor("white");
        setFastestOfferTabColor("white");
        setFastestOfferTabTextColor("black");
        setFastestRatedOfferTabColor("transparent");
        setFastestRatedOfferTabTextColor("white");
        setOfferType("fastestswap");
    }

    const fastestratedfun=()=>{
        setBestOfferTabColor("transparent");
        setBestOfferTabTextColor("white");
        setFastestOfferTabColor("transparent");
        setFastestOfferTabTextColor("white");
        setFastestRatedOfferTabColor("white");
        setFastestRatedOfferTabTextColor("black");
        setOfferType("bestrating");
    }

    const fixedfun=()=>{
        setFixedTabColor("white");
        setFixedTabTextColor("black");
        setFloatingTabColor("transparent");
        setFloatingTabTextColor("white");
        setFixedFilter("brightness(1%)");
        setFloatingFilter("brightness(2000%)");
        setTransactionType("Fixed");
    }

    const floatingfun=()=>{
        setFixedTabColor("transparent");
        setFixedTabTextColor("white");
        setFloatingTabColor("white");
        setFloatingTabTextColor("black");
        setFixedFilter("brightness(2000%)");
        setFloatingFilter("brightness(1%)");
        setTransactionType("Floating");
    }

    const [senddropvisibility, setSendDropVisibility] = useState("none"); // State to control dropdown visibility
    const [getdropvisibility, setGetDropVisibility] = useState("none"); // State to control dropdown visibility
    const [isLoading, setIsLoading] = useState(true);
    const [cr, setCr] = useState();
    const [sendcryptodrop, setSendCryptoDrop] = useState("none");
    const [getcryptodrop, setGetCryptoDrop] = useState("none");
    const [sendcryptoinput, setSendCryptoInput] = useState("flex");
    const [getcryptoinput, setGetCryptoInput] = useState("flex");
    const [sendindex, setSendIndex]=useState(localStorage.getItem("local_send_index"));
    const [getindex, setGetIndex]=useState(localStorage.getItem("local_get_index"));
    const [sendamount, setSendAmount]=useState(localStorage.getItem("local_send_amount"));
    const [getamount, setGetAmount]=useState("");
    const [loadingdots, setSetLoadingDots]=useState(false);
    const [exchangetab, setExchangeTab]=useState(true);
    const [buytab, setBuyTab]=useState(false);
    const [sendsearch, setSendSearch]=useState("");
    const [getsearch, setGetSearch]=useState("");
    const [sendcryptoarray, setSendCryptoArray]=useState([]);
    const [getcryptoarray, setGetCryptoArray]=useState([]);

    const handleSendSearchChange = (e) => {
      setSendSearch(e.target.value);
      const searchTerm = e.target.value.toLowerCase();
  
      const filteredCryptos = cr.filter((crypto) => {
        const { symbol2, symbol } = crypto;
        return (
          symbol2.toLowerCase().includes(searchTerm) ||
          symbol.toLowerCase().includes(searchTerm)
        );
      });
  
      const exactMatch = [];
      const partialMatch = [];
  
      filteredCryptos.forEach((crypto) => {
        const { symbol2, symbol } = crypto;
        if (
          symbol2.toLowerCase() === searchTerm ||
          symbol.toLowerCase() === searchTerm
        ) {
          exactMatch.push(crypto);
        } else {
          partialMatch.push(crypto);
        }
      });
  
      const rearrangedCryptos = exactMatch.concat(partialMatch);

      function countMatchingProperties(array, property, valueToMatch) {
        return array.filter(item => item[property] === valueToMatch).length;
    }

    const popular = countMatchingProperties(rearrangedCryptos, "popular", true);
    const stable = countMatchingProperties(rearrangedCryptos, "isstable", true);
    const other = countMatchingProperties(rearrangedCryptos, "othercoin", true);

    if(popular==0){
      setPopularCoinsVisibility("none");
    }else{
      setPopularCoinsVisibility("block");
    }
    if(stable==0){
      setStableCoinsVisibility("none");
    }else{
      setStableCoinsVisibility("block");
    }
    if(other==0){
      setOtherCoinsVisibility("none");
    }else{
      setOtherCoinsVisibility("block");
    }
      setSendCryptoArray(rearrangedCryptos);
      console.log(rearrangedCryptos);
    };

    const handleGetSearchChange = (e) => {
      setGetSearch(e.target.value);
      const searchTerm = e.target.value.toLowerCase();
  
      const filteredCryptos = cr.filter((crypto) => {
        const { symbol2, symbol } = crypto;
        return (
          symbol2.toLowerCase().includes(searchTerm) ||
          symbol.toLowerCase().includes(searchTerm)
        );
      });
  
      const exactMatch = [];
      const partialMatch = [];
  
      filteredCryptos.forEach((crypto) => {
        const { symbol2, symbol } = crypto;
        if (
          symbol2.toLowerCase() === searchTerm ||
          symbol.toLowerCase() === searchTerm
        ) {
          exactMatch.push(crypto);
        } else {
          partialMatch.push(crypto);
        }
      });
  
      const rearrangedCryptos = exactMatch.concat(partialMatch);

      function countMatchingProperties(array, property, valueToMatch) {
        return array.filter(item => item[property] === valueToMatch).length;
    }

    const popular = countMatchingProperties(rearrangedCryptos, "popular", true);
    const stable = countMatchingProperties(rearrangedCryptos, "isstable", true);
    const other = countMatchingProperties(rearrangedCryptos, "othercoin", true);

    if(popular==0){
      setPopularCoinsVisibility("none");
    }else{
      setPopularCoinsVisibility("block");
    }
    if(stable==0){
      setStableCoinsVisibility("none");
    }else{
      setStableCoinsVisibility("block");
    }
    if(other==0){
      setOtherCoinsVisibility("none");
    }else{
      setOtherCoinsVisibility("block");
    }
      setGetCryptoArray(rearrangedCryptos);
    };

    function setMin(val){
      setSendAmount(val);
    }

    useEffect(()=>{
        document.addEventListener("click", handleClickOutside, true);
    },[]);

    useEffect(()=>{
          // Get crypto function
    const getcrypto=async()=>{
      try {
        const default_array=JSON.parse(localStorage.getItem("coin_default_array"));
        setCr(default_array);
        setIsLoading(false);
        if(props.cryptoData){
          setCr(props.cryptoData);
          localStorage.setItem("coin_default_array", JSON.stringify(props.cryptoData));
        }
      } catch (error) {
        toast.error("Something didn't work as expected. Please try reloading the page.", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
      });
      }
    }
        getcrypto();
    },[props.cryptoData]);

    useEffect(() => {
      let intervalId; // Declare intervalId here
      window.scrollTo(0, 0);
      const fetchData=async()=>{
        setSetLoadingDots(true);

        // Check if it's not loading to prevent multiple API calls
        if (!isLoading) {
          setIsActive(prev => !prev);

        // Toggle the timer before calling the API  
          sessionStorage.setItem("local_send_ticker", !isLoading?cr[sendindex].symbol:0);
          sessionStorage.setItem("local_get_ticker", !isLoading?cr[getindex].symbol:1);
          localStorage.setItem("local_send_amount", sendamount);
          sessionStorage.setItem("local_send_amount", sendamount);
  
          localStorage.setItem("local_send_index",cr[sendindex].coinindex);
          sessionStorage.setItem("local_send_index",cr[sendindex].coinindex);
          sessionStorage.setItem("local_send_crypto_logo", cr[sendindex].image);
          sessionStorage.setItem("local_send_crypto_name", cr[sendindex].shortname.toUpperCase());
          sessionStorage.setItem("local_send_crypto_network", cr[sendindex].network.toUpperCase());

  
          localStorage.setItem("local_get_index",cr[getindex].coinindex);
          sessionStorage.setItem("local_get_index",cr[getindex].coinindex);
          sessionStorage.setItem("local_get_crypto_logo", cr[getindex].image);
          sessionStorage.setItem("local_get_crypto_name", cr[getindex].shortname.toUpperCase());
          sessionStorage.setItem("local_get_crypto_network", cr[getindex].network.toUpperCase());

          try {
            const url = process.env.REACT_APP_URL + `/offers`;

            const options={
                sell: cr[sendindex].symbol,
                get: cr[getindex].symbol,
                amount: sendamount,
                offerstype: offertype,
                fixed: transactiontype
            }
            const headers={
              headers: {
                "Content-Type": "application/json"
              }
            }

            const response2=await axios.post(url, options, headers);
            const data2=response2.data;
            setShowOnloadDummyOffers(false);
            setSetLoadingDots(false);
            setGetAmount(data2.bestoffer);
            setOfferArray(data2.offersarray);
            setTimerTrigger(true);

            handleOneTimeErrorState(false);
            toast.dismiss();
          } catch (error) {
            if(!handeonetimeerrorstate){
              handleToastError(false, "Please check your connection and try reloading the page.");
              handleOneTimeErrorState(true);
          }
          }
        }
      }
    
      // Function to restart the timer
      function restartInterval() {
        clearInterval(intervalId); // Clear the existing interval
        intervalId = setInterval(fetchData, 30000); // Set a new interval
      }
    
      // // Initial call to fetch data and start the timer
      fetchData();
      restartInterval();
    
      // Cleanup function to clear the interval
      return () => clearInterval(intervalId);
    }, [cr, sendamount, sendindex, getindex, offertype, transactiontype, timer]);
    

    // Send search visibility
   const toggleSendVisibility = () => {
    setSendCryptoDrop("flex");
    setSendCryptoInput("none");
    setSendDropVisibility("block");

    setGetCryptoDrop("none");
    setGetCryptoInput("flex");
    setGetDropVisibility("none");
    
    };

    // Get search visibility
   const toggleGetVisibility = () => {
    setGetCryptoDrop("flex");
    setGetCryptoInput("none");
    setGetDropVisibility("block");

    setSendCryptoDrop("none");
    setSendCryptoInput("flex");
    setSendDropVisibility("none");
    };

  const swapcryptoindex=()=>{ 
    if(!isLoading){

      const variable=sendindex;
      setSendIndex(getindex);
      setGetIndex(variable);
    }
  }

    //Handle outside clicks
    const handleClickOutside = (e) => {
        const cn = e.target.className;
    
        if (
          cn === "input-box" ||
          cn === "dropdown-content-send" ||
          cn === "dropdown-content-get" ||
          cn === "coin-drop-div" ||
          cn === "search-icon"||
          cn ==="exchange-send-div-input-drop-box"||
          cn === "form-control"||
          cn === "exchange-send-div-input"||
          cn === "exchange-send-label"||
          cn === "dont-close-send"||
          cn === "search-box" ||
          cn === "fa-solid fa-magnifying-glass search-icon"||
          cn === "form-control input-box"||
          cn === "btn  popular-currencies-btn"||
          cn === "btn  stable-currencies-btn"||
          cn === "btn  other-currencies-btn"

        ) {console.log("dont close")} 
        
        else {  
            setSendCryptoDrop("none");
            setSendCryptoInput("flex");
            setSendDropVisibility("none");

            setGetCryptoDrop("none");
            setGetCryptoInput("flex");
            setGetDropVisibility("none");

            setSendCryptoArray([]);
            setGetCryptoArray([]);

            setSendSearch("");
            setGetSearch("");

            setPopularCurrenciesTab(true);
            setStableCurrenciesTab(false);
            setOtherCurrenciesTab(false);
        }
      };

      function startTimer() {
        setTimer(prev => !prev);
    }

    const handleScrollToElementSend = (elementId) => {
      // Prevent the default anchor behavior
      const dropdownContent = document.querySelector('.scrollable-dropdown-send');
      const targetElement = document.getElementById(elementId);

      if (dropdownContent && targetElement) {
          const targetTop = targetElement.offsetTop;
          const dropdownScrollTop = dropdownContent.scrollTop;
          const dropdownHeight = dropdownContent.offsetHeight;
          console.log(dropdownHeight)

          // Calculate if the target element is out of view within the dropdown
          if (targetTop < dropdownScrollTop || (targetTop > dropdownScrollTop + dropdownHeight)) {
              // Align the target element at the top of the dropdown
              dropdownContent.scrollTop = targetTop-70;
          }
      }
  };

  const handleScrollToElementGet = (elementId) => {
    // Prevent the default anchor behavior
    const dropdownContent = document.querySelector('.scrollable-dropdown-get');
    const targetElement = document.getElementById(elementId);

    if (dropdownContent && targetElement) {
        const targetTop = targetElement.offsetTop;
        const dropdownScrollTop = dropdownContent.scrollTop;
        const dropdownHeight = dropdownContent.offsetHeight;
        console.log(dropdownHeight)

        // Calculate if the target element is out of view within the dropdown
        if (targetTop < dropdownScrollTop || (targetTop > dropdownScrollTop + dropdownHeight)) {
            // Align the target element at the top of the dropdown
            dropdownContent.scrollTop = targetTop-70;
        }
    }
};

    return(<>
    <Helmet>
    <title>Best Crypto-to-Crypto Exchange | Fast & Secure Swaps</title>
    <meta name="description" content="Experience the best crypto-to-crypto exchange on Coinoswap. Enjoy fast, secure, and convenient swaps on a non-custodial platform for all your crypto needs." />
    </Helmet>
    <div className='container-fluid'style={{paddingTop:"3%"}}>
        <div className='row'>
        <div className='col-xl-6 animate__animated animate__fadeInLeft offer-exchangebox-parent-div'>

            {/* Exchange Box */}
    <div className='exchange-box-div exchange-box-div-big-screen'>
    <div><div>Available Soon</div></div>
        <div className='exchange-box-tab'>
        <button type="button" className="btn  exchange-box-tab-exchange" style={{backgroundColor:exchangetab?"black":"#E3E1DE", color:exchangetab?"#E3E1DE":"black"}} onClick={()=>{setExchangeTab(true); setBuyTab(false);}}>Exchange Crypto</button>
        <button type="button" className="btn  exchange-box-tab-buy" style={{backgroundColor:buytab?"black":"#E3E1DE", color:buytab?"#E3E1DE":"black"}}><div>Buy/Sell Crypto<img src={credit_card} className='card_icon'></img></div></button>
        </div>
        <div className='exchange-inputs-div'>
            <div className='exchange-send-div'>
                <div  style={{display:sendcryptoinput, width:"100%"}}>
                <div className='exchange-send-div-input'>
                  <div>
                    <label className='exchange-send-label'>You Send:</label>
                    <input type="txt" className="form-control" id="exchange-box-send-input" aria-describedby="emailHelp"  value={sendamount} onChange={(e)=>{
                    // Replacing , with .
                    const modifiedValue = e.target.value.replace(/,/g, '.');
                              if (/^\d*\.?\d*$/.test(modifiedValue)) {
                                      setSendAmount(modifiedValue); // Update state only if input is valid (numbers and one decimal point)
                              }}}
                      />
                  </div>
                </div>
                <div className='exchange-send-div-input-drop'>
                    <div className='exchange-send-div-input-drop-box' id="thumb-cursor" onClick={toggleSendVisibility}>
                        <img src={isLoading?"":cr[sendindex].image} id="thumb-cursor" className='send-crypto-img dont-close-send'></img>
                        <div id="thumb-cursor" className='dont-close-send currency-send-label-div'>
                            <label id="thumb-cursor" className='dont-close-send btn-send-label'>{isLoading?"":cr[sendindex].shortname.toUpperCase()}</label>
                            { 
                               isLoading?"":<div className='network-div' style={{border:`1px solid ${cr[sendindex].networkcolor}`, display:cr[sendindex].networkcolor!==""?"block":"none"}}><span style={{color:cr[sendindex].networkcolor}}>{cr[sendindex].network.toUpperCase()}</span></div>                             
                            }                            
                            <i id="thumb-cursor" className='fa-solid fa-chevron-down dont-close-send fa-send'></i>
                        </div>

                    </div>
                </div>
                </div>
                <div className='search-box' style={{display:sendcryptodrop}}>
                    <i className="fa-solid fa-magnifying-glass search-icon" ></i>
                    <input type="txt" className="form-control input-box" placeholder="Type currency here" value={sendsearch} onChange={(e)=>{handleSendSearchChange(e)}}/>
                </div>
            </div>
            <div style={{width:"100%", position:"relative"}}>
            <div className="dropdown-content-send-offer" style={{display:senddropvisibility}}>
         <div className='type-of-coins-tab'>
        <button type="button" className="btn  popular-currencies-btn" style={{backgroundColor:popularcurrenciestab?"black":"#E3E1DE", color:popularcurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementSend('popular-send'); setPopularCurrenciesTab(true); setStableCurrenciesTab(false); setOtherCurrenciesTab(false);}}>Popular Currencies</button>
        <button type="button" className="btn  stable-currencies-btn" style={{backgroundColor:stablecurrenciestab?"black":"#E3E1DE", color:stablecurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementSend('stable-send'); setPopularCurrenciesTab(false); setStableCurrenciesTab(true); setOtherCurrenciesTab(false);}}>Stable Currencies</button>
        <button type="button" className="btn  other-currencies-btn" style={{backgroundColor:othercurrenciestab?"black":"#E3E1DE", color:othercurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementSend('other-send'); setPopularCurrenciesTab(false); setStableCurrenciesTab(false); setOtherCurrenciesTab(true);}}>Other Currencies</button>
        </div>

{isLoading?"":<div className='scrollbox scrollable-dropdown-send'>
      
      {/* Popular Coins Section */}
      <span id='popular-send' style={{ display: popularcoinsvisibility, fontFamily: "basementgrotesque", textAlign: "center" }}>Popular Currencies</span>
      
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            style={{display:popularcoinsvisibility}}
            height={65*(sendcryptoarray.filter(coin => coin.popular).length>0?sendcryptoarray.filter(coin => coin.popular).length:cr.filter(coin => coin.popular).length)} // Adjust height as needed
            rowCount={sendcryptoarray.filter(coin => coin.popular).length>0?sendcryptoarray.filter(coin => coin.popular).length:cr.filter(coin => coin.popular).length}
            rowHeight={65} // Adjust row height as needed
            rowRenderer={({ index, key, style }) => {
              const coin = sendcryptoarray.filter(coin => coin.popular).length>0?sendcryptoarray.filter(coin => coin.popular)[index]:cr.filter(coin => coin.popular)[index]
              return (
                <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => setSendIndex(coin.coinindex)}>
                  <div className='coin-drop-div'>
                    <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                      <img src={coin.image} id="thumb-cursor" style={{ height: "35px", width: "35px" }} />
                      <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                        <b id="thumb-cursor"><span id="thumb-cursor" style={{ display: "block" }}>{coin.shortname.toUpperCase()}</span></b>
                      </div>
                    </div>
                    <div className='dropdown-network-name' style={{ background: coin.networkcolor }}>{coin.network.toUpperCase()}</div>
                    <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                  </div>
                  <div className='coins-drop-underline' style={{ width: "100%", height: "1px" }}></div>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
      
      {/* Stable Coins Section */}
      <span id='stable-send' style={{ display: stablecoinsvisibility, fontFamily: "basementgrotesque", marginTop: "30px", textAlign: "center" }}>Stable Currencies</span>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            style={{display:stablecoinsvisibility}}
            height={65*(sendcryptoarray.filter(coin => coin.isstable).length>0?sendcryptoarray.filter(coin => coin.isstable).length:cr.filter(coin => coin.isstable).length)}
            // rowCount={cr.filter(coin => coin.isstable).length}
            rowCount={sendcryptoarray.filter(coin => coin.isstable).length>0?sendcryptoarray.filter(coin => coin.isstable).length:cr.filter(coin => coin.isstable).length}
            rowHeight={65}
            rowRenderer={({ index, key, style }) => {
              // const coin = cr.filter(coin => coin.isstable)[index];
              const coin = sendcryptoarray.filter(coin => coin.isstable).length>0?sendcryptoarray.filter(coin => coin.isstable)[index]:cr.filter(coin => coin.isstable)[index]
              return (
                <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => setSendIndex(coin.coinindex)}>
                  <div className='coin-drop-div'>
                    <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                      <img src={coin.image} id="thumb-cursor" style={{ height: "35px", width: "35px" }} />
                      <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                        <b id="thumb-cursor"><span id="thumb-cursor" style={{ display: "block" }}>{coin.shortname.toUpperCase()}</span></b>
                      </div>
                    </div>
                    <div className='dropdown-network-name' style={{ background: coin.networkcolor }}>{coin.network.toUpperCase()}</div>
                    <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                  </div>
                  <div className='coins-drop-underline' style={{ width: "100%", height: "1px" }}></div>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
      
      {/* Other Coins Section */}
      <span id='other-send' style={{ display: othercoinsvisibility, fontFamily: "basementgrotesque", marginTop: "30px", textAlign: "center" }}>Other Currencies</span>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            width={width}
            style={{display:othercoinsvisibility}}
            height={65*(sendcryptoarray.filter(coin => coin.othercoin).length>0?sendcryptoarray.filter(coin => coin.othercoin).length:cr.filter(coin => coin.othercoin).length)}
            // rowCount={cr.filter(coin => coin.othercoin).length}
            rowCount={sendcryptoarray.filter(coin => coin.othercoin).length>0?sendcryptoarray.filter(coin => coin.othercoin).length:cr.filter(coin => coin.othercoin).length}
            rowHeight={65}
            rowRenderer={({ index, key, style }) => {
              // const coin = cr.filter(coin => coin.othercoin)[index];
              const coin = sendcryptoarray.filter(coin => coin.othercoin).length>0?sendcryptoarray.filter(coin => coin.othercoin)[index]:cr.filter(coin => coin.othercoin)[index]
              return (
                <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => setSendIndex(coin.coinindex)}>
                  <div className='coin-drop-div'>
                    <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                      <img src={coin.image} id="thumb-cursor" style={{ height: "35px", width: "35px" }} />
                      <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                        <b id="thumb-cursor"><span id="thumb-cursor" style={{ display: "block" }}>{coin.shortname.toUpperCase()}</span></b>
                      </div>
                    </div>
                    <div className='dropdown-network-name' style={{ background: coin.networkcolor }}>{coin.network.toUpperCase()}</div>
                    <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                  </div>
                  <div className='coins-drop-underline' style={{ width: "100%", height: "1px" }}></div>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
      </div>}
        </div>
            </div>
            <div className='swap-btn-div'>
                <div className='swap-btn' id='thumb-cursor' onClick={swapcryptoindex}>
                    <img src={swap_icon} style={{height:"24px"}}></img>
                </div>
            </div>
            <div className='exchange-get-div'>
                <div  style={{display:getcryptoinput, width:"100%"}}>
                <div className='exchange-get-div-input'>
                  <div>
                    <label className='exchange-get-label'>You Recieve:</label>
                    <input type="txt" className="form-control" style={{display:loadingdots?"none":"block"}} id="exchange-box-get-input" aria-describedby="emailHelp" value={getamount} disable/>
                    <div className="dot-pulse" style={{ display:loadingdots?"block":"none"}}></div>
                  </div>
                </div>
                <div className='exchange-get-div-input-drop'>
                    <div className='exchange-get-div-input-drop-box' id="thumb-cursor" onClick={toggleGetVisibility}>
                    <img src={isLoading?"":cr[getindex].image} id="thumb-cursor" className="get-crypto-img dont-close-send"></img>
                    <div id="thumb-cursor" className='currency-get-label-div'>
                        <label id="thumb-cursor" className='btn-get-label'>{isLoading?"":cr[getindex].shortname.toUpperCase()}</label>
                        { 
                               isLoading?"":<div className='network-div' style={{border:`1px solid ${cr[getindex].networkcolor}`, display:cr[getindex].networkcolor!==""?"block":"none"}}><span style={{color:cr[getindex].networkcolor}}>{cr[getindex].network.toUpperCase()}</span></div>                             
                            }                        
                    <i id="thumb-cursor" className='fa-solid fa-chevron-down fa-get'></i>
                    </div>
                    </div>
                </div>
                </div>
                <div className='search-box' style={{display:getcryptodrop}}>
                    <i className="fa-solid fa-magnifying-glass search-icon" ></i>
                    <input type="txt" className="form-control input-box" placeholder="Type currency here" value={getsearch} onChange={(e)=>{handleGetSearchChange(e)}}/>
                </div>
            </div>
            <div style={{position:"relative", width:"100%"}}>
            <div className="dropdown-content-get-offer" style={{display:getdropvisibility}}>
                <div className='type-of-coins-tab'>
        <button type="button" className="btn  popular-currencies-btn" style={{backgroundColor:popularcurrenciestab?"black":"#E3E1DE", color:popularcurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementGet('popular-get'); setPopularCurrenciesTab(true); setStableCurrenciesTab(false); setOtherCurrenciesTab(false);}}>Popular Currencies</button>
        <button type="button" className="btn  stable-currencies-btn" style={{backgroundColor:stablecurrenciestab?"black":"#E3E1DE", color:stablecurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementGet('stable-get'); setPopularCurrenciesTab(false); setStableCurrenciesTab(true); setOtherCurrenciesTab(false);}}>Stable Currencies</button>
        <button type="button" className="btn  other-currencies-btn" style={{backgroundColor:othercurrenciestab?"black":"#E3E1DE", color:othercurrenciestab?"#E3E1DE":"black"}} onClick={()=>{handleScrollToElementGet('other-get'); setPopularCurrenciesTab(false); setStableCurrenciesTab(false); setOtherCurrenciesTab(true);}}>Other Currencies</button>
        </div>

        {!isLoading && (
  <div className="scrollbox scrollable-dropdown-get">

    {/* Popular Coins Section */}
    <span
      id="popular-get"
      style={{
        display: popularcoinsvisibility,
        fontFamily: "basementgrotesque",
        textAlign: "center",
      }}
    >
      Popular Currencies
    </span>

    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          style={{display:popularcoinsvisibility}}
          height={65*(getcryptoarray.filter(coin => coin.popular).length > 0 ? getcryptoarray.filter(coin => coin.popular).length : cr.filter(coin => coin.popular).length)} // Adjust height as needed
          rowCount={
            getcryptoarray.filter(coin => coin.popular).length > 0 ? getcryptoarray.filter(coin => coin.popular).length : cr.filter(coin => coin.popular).length
          }
          rowHeight={65} // Adjust row height as needed
          rowRenderer={({ index, key, style }) => {
            const coin =
              getcryptoarray.filter(coin => coin.popular).length > 0
                ? getcryptoarray.filter(coin => coin.popular)[index]
                : cr.filter(coin => coin.popular)[index];
            return (
              <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => setGetIndex(coin.coinindex)}>
                <div className="coin-drop-div">
                  <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                    <img
                      src={coin.image}
                      id="thumb-cursor"
                      style={{ height: "35px", width: "35px" }}
                      alt={coin.shortname}
                    />
                    <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                      <b id="thumb-cursor">
                        <span id="thumb-cursor" style={{ display: "block" }}>
                          {coin.shortname.toUpperCase()}
                        </span>
                      </b>
                    </div>
                  </div>
                  <div className="dropdown-network-name" style={{ background: coin.networkcolor }}>
                    {coin.network.toUpperCase()}
                  </div>
                  <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                </div>
                <div className="coins-drop-underline" style={{ width: "100%", height: "1px" }}></div>
              </div>
            );
          }}
        />
      )}
    </AutoSizer>

    {/* Stable Coins Section */}
    <span
      id="stable-get"
      style={{
        display: stablecoinsvisibility,
        fontFamily: "basementgrotesque",
        marginTop: "30px",
        textAlign: "center",
      }}
    >
      Stable Currencies
    </span>

    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          style={{display:stablecoinsvisibility}}
          height={65*(getcryptoarray.filter(coin => coin.isstable).length > 0 ? getcryptoarray.filter(coin => coin.isstable).length : cr.filter(coin => coin.isstable).length)}
          rowCount={
            getcryptoarray.filter(coin => coin.isstable).length > 0 ? getcryptoarray.filter(coin => coin.isstable).length : cr.filter(coin => coin.isstable).length
          }
          rowHeight={65}
          rowRenderer={({ index, key, style }) => {
            const coin =
              getcryptoarray.filter(coin => coin.isstable).length > 0
                ? getcryptoarray.filter(coin => coin.isstable)[index]
                : cr.filter(coin => coin.isstable)[index];
            return (
              <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => setGetIndex(coin.coinindex)}>
                <div className="coin-drop-div">
                  <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                    <img
                      src={coin.image}
                      id="thumb-cursor"
                      style={{ height: "35px", width: "35px" }}
                      alt={coin.shortname}
                    />
                    <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                      <b id="thumb-cursor">
                        <span id="thumb-cursor" style={{ display: "block" }}>
                          {coin.shortname.toUpperCase()}
                        </span>
                      </b>
                    </div>
                  </div>
                  <div className="dropdown-network-name" style={{ background: coin.networkcolor }}>
                    {coin.network.toUpperCase()}
                  </div>
                  <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                </div>
                <div className="coins-drop-underline" style={{ width: "100%", height: "1px" }}></div>
              </div>
            );
          }}
        />
      )}
    </AutoSizer>

    {/* Other Coins Section */}
    <span
      id="other-get"
      style={{
        display: othercoinsvisibility,
        fontFamily: "basementgrotesque",
        marginTop: "30px",
        textAlign: "center",
      }}
    >
      Other Currencies
    </span>

    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          width={width}
          style={{display:othercoinsvisibility}}
          height={65*(getcryptoarray.filter(coin => coin.othercoin).length > 0 ? getcryptoarray.filter(coin => coin.othercoin).length  : cr.filter(coin => coin.othercoin).length)}
          rowCount={
            getcryptoarray.filter(coin => coin.othercoin).length > 0 ? getcryptoarray.filter(coin => coin.othercoin).length  : cr.filter(coin => coin.othercoin).length
          }
          rowHeight={65}
          rowRenderer={({ index, key, style }) => {
            const coin =
              getcryptoarray.filter(coin => coin.othercoin).length > 0
                ? getcryptoarray.filter(coin => coin.othercoin)[index]
                : cr.filter(coin => coin.othercoin)[index];
            return (
              <div key={coin.coinindex} style={style} id="thumb-cursor" onClick={() => setGetIndex(coin.coinindex)}>
                <div className="coin-drop-div">
                  <div style={{ display: "flex", alignItems: "center", width: "35%" }}>
                    <img
                      src={coin.image}
                      id="thumb-cursor"
                      style={{ height: "35px", width: "35px" }}
                      alt={coin.shortname}
                    />
                    <div id="thumb-cursor" style={{ marginLeft: "10px" }}>
                      <b id="thumb-cursor">
                        <span id="thumb-cursor" style={{ display: "block" }}>
                          {coin.shortname.toUpperCase()}
                        </span>
                      </b>
                    </div>
                  </div>
                  <div className="dropdown-network-name" style={{ background: coin.networkcolor }}>
                    {coin.network.toUpperCase()}
                  </div>
                  <div style={{ width: "35%", textAlign: "end" }}>{coin.symbol2}</div>
                </div>
                <div className="coins-drop-underline" style={{ width: "100%", height: "1px" }}></div>
              </div>
            );
          }}
        />
      )}
    </AutoSizer>
  </div>
)}
        </div>
            </div>
        </div>

    </div>

            {/* Fixed and Float Buttons */}
            <div className='row fixed-float-tab' style={{marginTop:"36px"}}>
                <div className='col-6 basement-font vh-center' id="thumb-cursor" onClick={floatingfun} style={{backgroundColor:floatingtabcolor, color:floatingtabtextcolor, borderRadius:"40px"}}><img style={{width:"22px", marginRight:"10px", filter:floatingfilter}} src={floatingicon}></img><span>Floating</span></div>
                <div className='col-6 basement-font vh-center' id="thumb-cursor" onClick={fixedfun} style={{backgroundColor:fixedtabcolor, color:fixedtabtextcolor, borderRadius:"40px"}}><img style={{width:"20px", marginRight:"9px", filter:fixedfilter}} src={fixedicon}></img><span>Fixed</span></div>
            </div>
        </div>

        <div className='col-xl-6 animate__animated animate__fadeInRight'>
        
        {/* Offers Tabs Buttons */}
        <div className='offer-tab-container'>
        
        <div className='offers-type-tab row'>
        <div className='col-4 vh-center offer-type basement-font' id="thumb-cursor" onClick={bestratesfun} style={{backgroundColor:bestoffertabcolor, color:bestoffertabtextcolor}}><span>Best Rates</span></div>
        <div className='col-4 vh-center offer-type basement-font' id="thumb-cursor" style={{backgroundColor:fastestoffertabcolor, color:fastestoffertabtextcolor}}><div>Available Soon</div><span>Fastest Swap</span></div>
        <div className='col-4 vh-center offer-type basement-font' id="thumb-cursor" onClick={fastestratedfun} style={{backgroundColor:fastestratedoffertabcolor, color:fastestratedoffertabtextcolor}}><span>Best Rated</span></div>
        </div>
        <div className='clock-refresh-btn-container'>
        <div className='timer-circle-div' style={{position:"relative"}}>
        <CountdownCircleTimer
         key={isActive}
         isPlaying={timerTrigger}
         duration={30}
         colors={['rgba(244, 107, 12, 1)','#F7B801', '#A30000', '#A30000']}
         colorsTime={[5, 5, 2, 0]}
         size={40}
         strokeWidth={5}
        >
       {({ remainingTime }) => <div style={{ fontSize: 15, color: remainingTime <= 5 ? 'red' : 'rgba(244, 107, 12, 1)' }}>
            {remainingTime}
          </div>}
       </CountdownCircleTimer>
        <img className='img-fluid' src={refresh_circle} style={{width:"50px", zIndex:"2", position:"absolute"}}></img>
        </div>

        <div className="refreshbtn-div">
          <button className="refreshbtn" id='thumb-cursor' onClick={startTimer}>Refresh Search</button>
        </div>
        </div>
      </div>
        
            {/* Offers */}
            {
              // If condition
              showonloaddummyoffers?  
              // Condition 1               
              (onloaddummyoffers.map((offer,key)=>{
                return <StaticBestRatingOffers
                key={key}
                nooffer={false}   
                index={key}  
                name={offer.name} 
                type={offer.transaction_type} 
                eta={offer.eta} kyc={offer.kyc} 
                rating={offer.rating}
                offertype={offertype}
                >
                </StaticBestRatingOffers>
              })):
              // Condition 2
                (offerarray.length>0?offerarray.map((offer,key)=>{
                  let minimum=offer.min!=null?parseFloat(offer.min):0;
                  let newminimum=minimum+(minimum*0.002);
                  return <Offer 
                  key={key}
                  min={newminimum}
                  offerED={offer.offerED}
                  dotswhilefetch={loadingdots} 
                  index={key}
                  name={offer.name} 
                  rate={offer.rate} 
                  type={offer.transaction_type} 
                  eta={offer.eta} 
                  kyc={offer.kyc} 
                  rating={offer.rating}
                  offertype={offertype}
                  offer_type={offer.offer_type}
                  rateid={offer.rateId?offer.rateId:""}
                  setMin={setMin}
                  >
                  </Offer>
                }):<div className='nooffer-div'><span>This Trading Pair Is Not Available. Please Choose Another Pair.</span></div>)
              
            }
        </div>
        </div>
    </div>
    <div className='container-fluid step2-desktop'><div className='row' style={{margin:"5% 0%", marginBottom:"10%"}}><img src={step2} className='img-fluid'></img></div></div>
    <div className='container-fluid step2-mobile'><div className='row' style={{margin:"5% 0%", marginBottom:"10%"}}><img src={step2mobileui} className='img-fluid'></img></div></div>
    </>);
}

export default Offers;